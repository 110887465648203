import React, { useState, useEffect } from 'react';
import SettingDataMenu from '../SettingData/SettingDataMenu';
import SettingDataContent from '../SettingData/SettingDataContent';
import s from "./Settings.module.css"

const Settings = () => {
    const [selectedMenu, setSelectedMenu] = useState(null);

    const handleMenuChange = (menu) => {
        setSelectedMenu(menu);
    };

    return (
        <div className={s.main}>
            <div className={s.menu}>
                <SettingDataMenu onMenuChange={handleMenuChange} />
            </div>
            <div className={s.forms}>
                {selectedMenu !== null &&
                    <SettingDataContent selectedMenu={selectedMenu} onMenuChange={handleMenuChange} />}
            </div>
        </div>
    )
}
export default Settings;