import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import '../../../src/i18n';
import { TextField, Button, Link, Grid, Container, Box } from '@mui/material';
import { AlertBar } from '../Utils/Utils';



export default function LoginForm({ onLogin }) {
    

    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [alertInfo, setAlertInfo] = useState({ severity: 'info', message: '' });
    const navigate = useNavigate();

    console.log(
        process.env.REACT_APP_API_URL
    );
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/users/login`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: values.email,
                        password: values.password,
                    }),
                });

                const data = await response.json();

                if (response.ok) {
                    console.log('Login Successful', data);
                    onLogin(data);
                    setAlertInfo({ severity: 'success', message: t('loginSuccess') });
                    setOpen(true);
                    navigate('/map');
                } else {
                    console.error('Login Failed:', data.message);
                    setAlertInfo({ severity: 'error', message: data.message || t('loginFailed') });
                    setOpen(true);
                }
            } catch (error) {
                console.error('Network error:', error);
                setAlertInfo({ severity: 'error', message: t('networkError') });
                setOpen(true);
            }

            setSubmitting(false);
        },
    });

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <Container maxWidth="sm">
            <Box
                sx={{
                    marginTop: 12,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                <img
                    src="/logo.svg"
                    alt="Logo"
                    width={"100%"}
                    height={"50%"}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <form onSubmit={formik.handleSubmit}>
                    <TextField
                        fullWidth
                        margin="normal"
                        id="email"
                        name="email"
                        label={t('email')}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        id="password"
                        name="password"
                        label={t('password')}
                        type="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                    />
                    <Button
                        color="primary"
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}>
                        {t('login')}
                    </Button>
                    <AlertBar open={open} handleClose={handleClose} message={alertInfo.message} severity={alertInfo.severity} >

                    </AlertBar>
                    <Grid container justifyContent={'right'}>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                {t('forgotpass')}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="/registration" variant="body2">
                                {t('registration')}
                            </Link>
                        </Grid>
                    </Grid>


                </form>
            </Box>
        </Container>
    )
}
