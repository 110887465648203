import React, { useState, useRef, useContext } from 'react';
import s from "./ReportButtons.module.css"
import { UserContext } from "../../App";
import { showAccessAlert } from "../Utils/Utils";

function ReportButtons({ handleLogsClick, handleExportCSV, handleExportXLSX, toggleLogTable }) {
    const arrayButton = ['Выгрузка', 'Автоматизация', 'Лог', 'Шаблон'];
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const { user, setUser } = useContext(UserContext);
    const notAllowedGroups = ['USER_ORG'];
    const isNotAllowed = notAllowedGroups.includes(user.role);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleButtonClick = (buttonText) => {
        if (buttonText === 'Отчёт о потреблении (ЕРИП)') {
            handleExportCSV();
        } else if (buttonText === 'Отчёт за период') {
            handleExportXLSX();
        } else if (buttonText === 'Лог') {
            handleLogsClick();
        } else if (buttonText === 'Шаблон') {
            if (notAllowedGroups.includes(user.role)) {
                showAccessAlert();
                return;
            }
            setShowDropdown(false); // Close dropdown on button click
        } else {
            setShowDropdown(false);
        }
    };

    return (
        <div className={s.buttonGroup}>
            {arrayButton.map((buttonText, index) => (
                <div key={index}>
                    {buttonText === 'Выгрузка' ? (
                        <div ref={dropdownRef} className={s.frame16} onClick={toggleDropdown}>
                            <div className={s.frame16Text}>
                                {buttonText}
                            </div>
                            {showDropdown && (
                                <div
                                    className="dropdown"
                                    style={{
                                        position: 'absolute',
                                        left: 0,
                                        zIndex: 999,
                                        backgroundColor: '#fff',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                        padding: '5px',
                                        top: '50px'
                                    }}
                                >
                                    <button
                                        key={0}
                                        type="button"
                                        className="btn btn-outline-dark"
                                        style={{
                                            display: 'block',
                                            width: '100%',
                                            marginBottom: '5px',
                                        }}
                                        onClick={() =>
                                            handleButtonClick('Отчёт о потреблении (ЕРИП)')
                                        }
                                    >
                                        Отчёт о потреблении (ЕРИП)
                                    </button>
                                    <button
                                        key={0}
                                        type="button"
                                        className="btn btn-outline-dark"
                                        style={{
                                            display: 'block',
                                            width: '100%',
                                            marginBottom: '5px',
                                        }}
                                        onClick={() =>
                                            handleButtonClick('Отчёт за период')
                                        }
                                    >
                                        Отчёт за период
                                    </button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className={s.frame16} onClick={() => handleButtonClick(buttonText)}>
                            <div className={s.frame16Text} >
                                {buttonText}
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

export default ReportButtons;
