import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../src/i18n';



export default function Registration() {
    const navigate = useNavigate();
    


    const [form, setForm] = useState({
        username: '',
        email: '',
        password: '',
    });
    
    const [message, setMessage] = useState(null);

    const handleChange = (event) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    
        fetch(`${process.env.REACT_APP_API_URL}/users/registration`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(form),
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((data) => {
            console.log('Success:', data);
            setMessage('Регистрация прошла успешно!');
            navigate('/login');
        })
        .catch((error) => {
            console.error('Error:', error);
            setMessage('Ошибка регистрации: ' + error.message);
        });
    };

    return (
        <div className='registration' id='registration'>
            <img
                    src="/logo.svg"
                    alt="Logo"
                    width={500}
                    height={250}
                />
            {message && <p>{message}</p>}
            <form onSubmit={handleSubmit}>
                <label>
                    ФИО или название организации:
                </label>
                <input
                    type='text'
                    name='username'
                    value={form.username}
                    onChange={handleChange}
                />
                <label>
                    Электронная почта:
                </label>
                <input
                    type='email'
                    name='email'
                    value={form.email}
                    onChange={handleChange}
                />
                <label>
                    Пароль:
                </label>
                <input
                    type='password'
                    name='password'
                    value={form.password}
                    onChange={handleChange}
                />
                <input type="submit" value="Регистрация" />
            </form>
        </div>
    );
}





