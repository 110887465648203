import React, { useState, useEffect } from 'react';
import s from "./Sidebar.module.css"
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import useIsMobile from '../../store/mobileHook';


export default function Sidebar({ setActiveSideBar, setActiveComponent, handleMenuToggle }) {
    const isMobile = useIsMobile();

    const [activeItem, setActiveItem] = useState('');

    const location = useLocation();


    const navigate = useNavigate();

    const handleItemClick = (item) => {
        setActiveItem(item);
        if (item === 'item1') {
            navigate('/map');
        }
        else if (item === 'item2') {
            navigate('/devices');
            setActiveComponent('Devices')
        }
        else if (item === 'item3') {
            navigate('/reports');
        }
        else if (item === 'item4') {
            navigate('/settings');
        }
        
        if (isMobile) {
            setActiveSideBar(false);
        }
    };

    useEffect(() => {
        if (location.pathname === '/map') {
            setActiveItem('item1');
        } else if (location.pathname === '/devices') {
            setActiveItem('item2');
        } else if (location.pathname === '/reports') {
            setActiveItem('item3');
        } else if (location.pathname === '/settings') {
            setActiveItem('item4');
        }
    }, [location.pathname]);


    return (
        <div className={s.sideBarFrame}>
            <div className={s.sideBarBox}>
                <div className={s.sideBarGroup}>
                    <div
                        className={`${s.item1} ${activeItem === 'item1' ? s.active : ''}`}
                        onClick={() => handleItemClick('item1')}
                    >
                        <div className={s.locationOn}>
                            <img src={'/map.svg'} className={activeItem === 'item1' ? s.activeIcon : ''}></img>
                        </div>
                        <div className={s.locationText}>Карта</div>
                    </div>
                    <div
                        className={`${s.item2} ${activeItem === 'item2' ? s.active : ''}`}
                        onClick={() => handleItemClick('item2')}
                    >
                        <div className={s.vibration}>
                            <img src={'/device.svg'} className={activeItem === 'item2' ? s.activeIcon : ''}></img>
                        </div>
                        <div className={s.vibrationText}>Устройства</div>
                    </div>
                    <div
                        className={`${s.item3} ${activeItem === 'item3' ? s.active : ''}`}
                        onClick={() => handleItemClick('item3')}
                    >
                        <div className={s.article}>
                            <img src={'/article.svg'} className={activeItem === 'item3' ? s.activeIcon : ''}></img>
                        </div>
                        <div className={s.articleText}>Отчеты</div>
                    </div>
                </div>
                <div
                    className={`${s.item4} ${activeItem === 'item4' ? s.active : ''}`}
                    onClick={() => handleItemClick('item4')}
                >
                    <div className={s.setting}>
                        <img src={'/settingmenu.svg'} className={activeItem === 'item4' ? s.activeIcon : ''}></img>
                    </div>
                    <div className={s.settingText}>Настройки</div>
                </div>
            </div>
            {!isMobile ? (
                <div className={s.arrowBack}>  {/* onClick={() => setActiveSideBar(false)}> */}
                    <img src={'/hidelogo.svg'}></img>
                </div>
            ) : (
                <></>
            )}


        </div>


    )

}

