import React, { useRef, useEffect, useState, useContext } from 'react';
import { UserContext } from "../../App";
import { useNavigate } from "react-router-dom";

import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken = 'pk.eyJ1Ijoib21vZWdvZCIsImEiOiJjbG5pbzd6bWQwOWM0MmlzM2l6cGdoencwIn0.i3XwPBMrrr1g2n3_xr7YLQ';


export const MapBox = () => {
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();
    


    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(27.567444);
    const [lat, setLat] = useState(53.893009);
    const [zoom, setZoom] = useState(7);
    const [devices, setDevices] = useState([]);

    const getPriorityStatus = (statuses) => {
        if (statuses.includes('WARN')) {
            return 'WARN';
        } else if (statuses.includes('OFFLINE')) {
            return 'OFFLINE';
        } else {
            return 'ONLINE';
        }
    };

    const generatePopupContent = (devices, address) => {
        const totalDevices = devices.length;
        const onlineDevices = devices.filter((device) => device.data[0].status === 'ONLINE').length;
        const offlineDevices = devices.filter((device) => device.data[0].status === 'WARN').length;
        const warnDevices = devices.filter((device) => device.data[0].status === 'PROBLEM').length;

        return `
            <div>
                <p>Всего устройств: <span style="color: blue">${totalDevices} </span></p>
                <p>В сети: <span style="color: green">${onlineDevices}</span></p>
                <p>С ошибками: <span style="color: orange">${warnDevices}</span></p>
                <p>Не в сети: <span style="color: red">${offlineDevices}</span></p>
                <button data-address="${address}" class="navigate-button">Перейти к устройствам</button>
            </div>
        `;
    };

    useEffect(() => {
        const handleNavigateClick = (event) => {
            if (event.target.classList.contains("navigate-button")) {
                const address = event.target.getAttribute("data-address");
                console.log(address);
                navigate("/devices", { state: { address } });
            }
        };

        document.addEventListener("click", handleNavigateClick);
        return () => {
            document.removeEventListener("click", handleNavigateClick);
        };
    }, [navigate]);

    useEffect(() => {
        async function fetchDevices() {
            const url = new URL(`${process.env.REACT_APP_API_URL}/device/device-list`);

            user.cs_group.forEach(csGroup => {
                url.searchParams.append('cs_group_id', csGroup._id);
            });
            user.nbiot_group.forEach(nbiotGroup => {
                url.searchParams.append('nbiot_group_id', nbiotGroup._id);
            });

            let response = await fetch(url);
            let data = await response.json();
            console.log(data);

            const groupedDevices = data.reduce((acc, device) => {
                const key = `${device.address[0].lng}_${device.address[0].lat}`;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(device);
                return acc;
            }, {});

            Object.keys(groupedDevices).forEach((key) => {
                const devicesAtLocation = groupedDevices[key];
                
                const statuses = devicesAtLocation.map((device) => device.data[0].status);
                console.log(devicesAtLocation);
                const address = devicesAtLocation.map((device) => 
                    [device.address[0].country, device.address[0].region, device.address[0].city, device.address[0].street, device.address[0].building].filter(field => field));

                const popupContent = generatePopupContent(devicesAtLocation, address);

                const el = document.createElement('div');
                el.className = 'marker';
                el.style.width = '46px';
                el.style.height = '46px';
                el.style.borderRadius = '50%';
                el.style.backgroundColor = '#466AE9'
                el.style.border = "2px solid #3256D5"
                el.style.display = "flex"
                el.style.flexDirection = "column"
                el.style.justifyContent = "center"
                el.style.alignItems = "center"
                el.style.padding = "0px"
                el.style.boxShadow = "0px 5px 10px rgba(9, 34, 57, 0.2)"

                const textContent = devicesAtLocation.length
                const text = document.createElement('div');
                text.className = "text";
                text.style.width = "auto"
                text.style.height = "22px"
                text.style.textAlign = "center"
                text.style.fontSize = "22px"
                text.style.color = "#fff"
                text.style.flex = "none"
                text.style.flexGrow = "0"
                text.style.order = "0"
                text.textContent = textContent


                el.appendChild(text);

                const popup = new mapboxgl.Popup()
                    .setLngLat([
                        Number(devicesAtLocation[0].address[0].lng),
                        Number(devicesAtLocation[0].address[0].lat),
                    ])
                    .setHTML(popupContent)

                const marker = new mapboxgl.Marker(el)
                    .setLngLat([
                        Number(devicesAtLocation[0].address[0].lng),
                        Number(devicesAtLocation[0].address[0].lat),
                    ])
                    .setPopup(popup)
                    .addTo(map.current);

                marker.getElement().addEventListener('mouseenter', () => {
                    popup.addTo(map.current);
                });

                // marker.getElement().addEventListener('mouseleave', () => {
                //         popup.remove();
                //     });    
            });
        }
        fetchDevices();
    }, []);

    useEffect(() => {
        if (map.current) return;
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [lng, lat],
            zoom: zoom
        });



        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });
        const language = new MapboxLanguage();
        map.current.addControl(language);

    }, [devices]);

    return (
        <div>
            <div ref={mapContainer} className="map-container" />
        </div>
    );
}

export default MapBox;