
export function utilsDatetime() {
  const timestamp = Date.now();
  const date = new Date(timestamp);
  const humanReadableDate = date.toLocaleString();
  return humanReadableDate
}

export async function exportVerifyData(date) {
  const authToken = localStorage.getItem('token');
  

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/verifydevice?date=${date}`, {
      method: 'POST',
    });
    if (response.ok) {
      // Получение URL для скачивания файла
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Отчет_о_поверке_${utilsDatetime()}.xlsx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } else {
      // Обработка ошибок
      console.error('Error:', response.statusText);
    }
  } catch (error) {
    // Обработка ошибок сети или других проблем
    console.error('Error:', error);
  }
}

export async function exportToExel({ dataToExport }) {
  const authToken = localStorage.getItem('token');
  


  const data = dataToExport;
  console.log(data);
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/reports/excel`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data }),
    });

    if (response.ok) {
      // Получение URL для скачивания файла
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Отчет_за_период_${utilsDatetime()}.xlsx`;

      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } else {
      // Обработка ошибок
      console.error('Error:', response.statusText);
    }
  } catch (error) {
    // Обработка ошибок сети или других проблем
    console.error('Error:', error);
  }
}


export async function exportDeviceToExel({ dataToExport }) {
  
  const data = dataToExport;
  console.log(data);
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/device/exceldevice`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data }),
    });

    if (response.ok) {
      // Получение URL для скачивания файла
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Отчет_потребления_устройства_${utilsDatetime()}.xlsx`;

      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } else {
      // Обработка ошибок
      console.error('Error:', response.statusText);
    }
  } catch (error) {
    // Обработка ошибок сети или других проблем
    console.error('Error:', error);
  }

}



export async function exportTemplatesXLSX({ dataToExport }) {
  
  try {
    const url = new URL(`${process.env.REACT_APP_API_URL}/reports/device-thermal`);
    url.searchParams.append('_id', dataToExport.deviceUuid);
    url.searchParams.append('dev_number', dataToExport.deviceNumber);
    url.searchParams.append('template', dataToExport.template);
    url.searchParams.append('org', dataToExport.org);
    url.searchParams.append('fromDate', dataToExport.startDate);
    url.searchParams.append('toDate', dataToExport.endDate);

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    if (response.ok) {
      // Получение URL для скачивания файла
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Отчет_${utilsDatetime()}.xlsx`;

      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } else {
      // Обработка ошибок
      console.error('Error:', response.statusText);
    }
  } catch (error) {
    // Обработка ошибок сети или других проблем
    console.error('Error:', error);
  }

}

export async function exportDevicesToExcel({ dataToExport }) {
  

  console.log(dataToExport);

  const data = dataToExport;
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/device/export`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data }),
    });

    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Экспорт_устройств_${utilsDatetime()}.xlsx`;

      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } else {
      console.error('Error:', response.statusText);
    }
  } catch (error) {
    console.error('Error:', error);
  }

}    

export async function exportErip(dataToExport) {
  

  const data = dataToExport;
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/reports/eripcsv`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data }),
    });

    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Отчет_для_ЕРИП_${utilsDatetime()}.txt`;

      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } else {
      console.error('Error:', response.statusText);
    }
  } catch (error) {
    console.error('Error:', error);
  }

}    