import React, { useEffect, useState, useContext } from 'react';
import { filterFullAddress, getReports } from '../../store/deviceSlice';
import { Link } from 'react-router-dom';
import { ReportChoiceData } from '../ReportUtils/ReportUtils';
import ReportButtons from '../ButtonsGroup/ReportButtons';
import { exportToExel, utilsDatetime, exportErip } from '../../store/exportToExcel';
import { formatDateUnix } from '../Layout/DeviceInfo';
import { UserContext } from '../../App';
import s from "./ReportsTable.module.css"

const PaginationTable = ({ headers, filteredDevices, selectedOption, currentPage, itemsPerPage }) => {

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredDevices.slice(indexOfFirstItem, indexOfLastItem);
    console.log(currentItems);
    const renderItems = currentItems.map((result, index) => (
        <tr key={`${index}`}>
            {headers.map((header) => (
                <td key={header}>
                    <div className={s.tableBodyText}>
                        {header === 'Детали' ? (
                            <Link to={`/device/${result.device._id}&${result.device.device[0].dev_number}`} id={`${result.device.device[0].dev_number}`}>
                                <img src={'/document.svg'} />
                            </Link>
                        ) : header === 'Тип' ? (

                            result.device.device[0].dev_type === "ВС" && result.device.device[0].resourse_type === "Горячая вода" ? "ГВС"
                                : result.device.device[0].dev_type === "ВС" && result.device.device[0].resourse_type === "Холодная вода" ? "ХВС"
                                    : result.device.device[0].dev_type === "ТС" ? "ТС"
                                        : "ВС"

                        )
                            : header === 'Номер счетчика' ? (
                                result.device.device[0].dev_number
                            ) : header === 'Адрес' ? (
                                `${result.device.address[0].country ? result.device.address[0].country + ', ' : ''}` +
                                `${result.device.address[0].region ? result.device.address[0].region + ', ' : ''}` +
                                `${result.device.address[0].city ? result.device.address[0].city + ', ' : ''}` +
                                `${result.device.address[0].street ? result.device.address[0].street + ', ' : ''}` +
                                `${result.device.address[0].building ? result.device.address[0].building + (result.device.address_number !== null ? ', ' : '') : ''}` +
                                `${result.device.address_number !== null && result.device.address_number !== undefined ? result.device.address_number : ''}`
                            ) : header === 'Показания' ? (
                                `${(parseFloat(result.device_frmpayload_data_volume + result.device.data[0].start_volume) * (result.device.device[0].factor)).toFixed(3)} 
                                ${result.device.device[0].dev_type === 'ВС' ? 'м³'
                                    : result.device.device[0].dev_type === 'ТС' ? 'ГДж'
                                        : 'кВт-Ч'}`
                            ) : header === 'Дата показаний' ? (
                                formatDateUnix(result.device_frmpayload_data_timestamp)
                            )
                                : (
                                    result[header]
                                )}
                    </div>
                </td>
            ))}
        </tr>
    ))


    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredDevices.length / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <>{renderItems}</>
    );
};


function ReportTable({ handleLogsClick, fullAddress }) {
    const [allDevices, setAllDevices] = useState([]);
    const [filteredDevices, setFilteredDevices] = useState([]);
    const [searchAddress, setSearchAddress] = useState('');
    const [selectedOption, setSelectedOption] = useState('Все');
    const [utilsData, setutilsData] = useState([]);
    const { user, setUser } = useContext(UserContext);

    var username = `${user.email}`;


    const handleDataFromChild = (data) => {
        setutilsData(data);
    };

    const handleExportXLSX = () => {
        const readingdate = utilsDatetime()
        const dataToExport = {
            filteredDevices,
            utilsData,
            username,
            readingdate
        };
        exportToExel({ dataToExport }) // Вызов функции для отправки данных
            .then(() => {
                console.log('Данные успешно отправлены для отчета за период');
            })
            .catch(error => {
                console.error('Ошибка отправки данных для отчета за период:', error);
            });
    };

    const handleExportCSV = () => {
        const originalData = filteredDevices;
        exportErip(originalData);
    };


    useEffect(() => {
        if (utilsData && utilsData.length === 3) {
            getReports({ utilsData, user })
                .then(data => {

                    const allResults = data.flatMap(item =>
                        item.result.map(result => ({ ...result, device: item }))
                    );
                    setAllDevices(allResults);
                    setFilteredDevices(allResults);
                })
                .catch(error => {
                    console.error('Ошибка при получении отчетов:', error);
                });
        } else {
            console.warn('utilsData is invalid. Skipping API call.');
        }
    }, [utilsData]);


    useEffect(() => {
        let filtered = allDevices.slice();

        if (searchAddress !== '') {
            filtered = filtered.filter(item =>
                `${item.address[0].country}, ${item.address[0].region}, ${item.address[0].city}, ${item.address[0].street}, ${item.address[0].building}, ${item.device_iot.deveui}`
                    .toLowerCase()
                    .includes(searchAddress.toLowerCase())
            );
        }

        if (selectedOption !== 'Все') {
            filtered = filtered.filter(item =>
                (item.device.device[0].dev_type === 'ВС' ? 'ВС'
                    : item.device.device[0].dev_type === 'ТС' ? 'ТС'
                        : 'ЭС')
                    .toLowerCase()
                    .includes(selectedOption.toLowerCase())
            );
        }

        if (fullAddress !== null && fullAddress.length > 0) {


            filtered = filtered.filter(item => {
                console.log(item);
                let itemAddress = `${item.device.address[0].country}, ${item.device.address[0].region}, ${item.device.address[0].city}, ${item.device.address[0].street}, ${item.device.address[0].building}`.toLowerCase();

                return fullAddress.every(addr => itemAddress.includes(addr.toLowerCase()));
            });
        }
        setFilteredDevices(filtered);

    }, [fullAddress, selectedOption, allDevices, searchAddress]); //searchAddress, selectedOption, allDevices, fullAddress


    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemPerPage] = useState(10);

    let quantity = 0;
    if (filteredDevices) {
        quantity = filteredDevices.length;
    }

    let totalPages = 0;
    if (filteredDevices) {
        totalPages = Math.ceil(filteredDevices.length / itemsPerPage);
    }

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        if (totalPages > 1) {
            setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
        }
    };

    const handleItemsPerPageChange = (event) => {
        setItemPerPage(Number(event.target.value));
        setCurrentPage(1);
    };



    const headers = ['Номер счетчика', 'Адрес', 'Тип', 'Показания', 'Дата показаний', 'Детали'];

    return (
        <>
            <ReportButtons handleExportCSV={handleExportCSV} handleExportXLSX={handleExportXLSX} handleLogsClick={handleLogsClick} />
            <div className={s.tableContainer}>
                <div className={s.warp}>
                    <ReportChoiceData sendDataToParent={handleDataFromChild} />
                    <div>
                        <div className={s.type}>Тип устройства: </div>
                        <select className={s.tableSelect} onChange={(e) => setSelectedOption(e.target.value)}>
                            <option value='Все'>Все</option>
                            <option value='ВС'>ВС</option>
                            <option value='ЭС'>ЭС</option>
                            <option value='ТС'>ТС</option>
                        </select>
                    </div>
                    <div className={s.table}>

                        <div className={s.tableHeader}>
                            <table>
                                <thead>
                                    <tr>
                                        {headers.map((header) => (
                                            <th key={header}>
                                                {header}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div className={s.tableBody}>
                            <table>
                                <tbody>
                                    <PaginationTable headers={headers} filteredDevices={filteredDevices} selectedOption={selectedOption} currentPage={currentPage} itemsPerPage={itemsPerPage} />
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={s.paginate}>
                        <div className={s.paginateText}>{quantity} всего</div>
                        <div className={s.devicePerPage}>
                            <div className={s.devicePerPageText}>Показать</div>
                            <select className={s.inputPerPage} value={itemsPerPage} onChange={handleItemsPerPageChange}>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                        <div className={s.paginateInput}>
                            <div className={s.arrowLeft} onClick={handlePreviousPage}>
                                <img src={'/arrowleft.svg'}></img>
                            </div>
                            <div className={s.page}>{currentPage}</div>
                            <div className={s.arrowRight} onClick={handleNextPage}>
                                <img src={'/arrowright.svg'}></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportTable;
