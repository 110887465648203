import React, { useState, useEffect, useContext } from 'react';
import s from "./Header.module.css"
import { UserContext } from "../../App";
import { useNavigate } from 'react-router-dom';
import useIsMobile from '../../store/mobileHook';
import Sidebar from './Sidebar';
import { useLocation } from 'react-router-dom';



export default function Header() {
    const isMobile = useIsMobile();
    const { user, setUser } = useContext(UserContext);
    const [deviceList, setDeviceList] = useState([]);
    const navigate = useNavigate();
    const [activeSideBar, setActiveSideBar] = useState(true)
    const [activeComponent, setActiveComponent] = useState('Devices');

    console.log(activeSideBar);
    
    useEffect(() => {
        if (isMobile) {
            setActiveSideBar(false);
        }
    }, [location, isMobile]);

    useEffect(() => {
        const fetchLabels = async () => {

            const url = new URL(`${process.env.REACT_APP_API_URL}/label/get`);

            user.cs_group.forEach(csGroup => {
                url.searchParams.append('cs_group_id', csGroup._id);
            });
            user.nbiot_group.forEach(nbiotGroup => {
                url.searchParams.append('nbiot_group_id', nbiotGroup._id);
            });
            const response = await fetch(url);
            const data = await response.json();
            setDeviceList(data)
        };

        fetchLabels();
    }, []);

    const handleLogout = () => {
        localStorage.clear();
        navigate('/login');
    };

    const handleMenuToggle = () => {
        setActiveSideBar(prevState => !prevState);
    };

    return (
        <div className={s.header}>
            {!isMobile ? (
                <div className={s.logo}></div>
            ) : (
                <>
                    <div className={s.mobileMenu} onClick={handleMenuToggle}>
                        <img src={'/menu.svg'}></img>
                    </div>
                    <div className={s.logo}></div>
                    <div className={s.mobileUser}>
                        <img src={'/user.svg'}></img>
                    </div>
                    {activeSideBar ? (
                        <Sidebar setActiveSideBar={setActiveSideBar} setActiveComponent={setActiveComponent} handleMenuToggle={handleMenuToggle} />
                    ) : (
                        <></>
                    )}
                </>
            )
            }

            {!isMobile ? (<>
                <div className={s.label}>
                    <div className={s.grid1}>
                        <span>всего устройств</span>
                        <div className={s.gridItem1}>
                            <span>{deviceList.totalRows}</span>
                        </div>
                    </div>
                    <div className={s.grid2}>
                        <span>в сети</span>
                        <div className={s.gridItem2}>
                            <span>{deviceList.onlineRows}</span>
                        </div>
                    </div>
                    <div className={s.grid3}>
                        <span>с ошибками</span>
                        <div className={s.gridItem3}>
                            <span>{deviceList.problemRows}</span>
                        </div>
                    </div>
                    <div className={s.grid4}>
                        <span>не в сети</span>
                        <div className={s.gridItem4}>
                            <span>{deviceList.warnRows}</span>
                        </div>
                    </div>
                </div>
                <div className={s.user}>
                    <span>{user.email}</span>
                    <div className={s.userIcon} onClick={handleLogout}>
                        <img src={'/logout.svg'}></img>
                    </div>
                </div>
            </>
            ) : (<></>)
            }
        </div>
    )
}

