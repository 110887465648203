import React, { useState, useEffect, useContext } from 'react';
import { TenantForm, CreateUserForm, UserForm, ProfileForm, GatewayForm, VerifyForm, AddressForm, ApplicationForm, updateUserContext, NbiotOrgForm, NbiotGroupForm } from './Forms';
import { UserContext } from "../../App";

import s from "./SettingData.module.css"
import Paper from '@mui/material/Paper';
import UsersTable from './SettingDataUsers'

function SettingDataContent({ selectedMenu, onMenuChange }) {
    const { user, setUser } = useContext(UserContext);
    console.log(selectedMenu);

    const forms = [
        { id: "report", name: "Отчет по поверкам", component: <VerifyForm /> },
        { id: "users", name: "Создание Пользователей", component: <UsersTable onMenuChange={onMenuChange} /> },
        { id: "createUser", name: "Создание Пользователей", component: <CreateUserForm /> },
        { id: "createUserChirpstack", name: "Создание Пользователей Chirpstack", component: <UserForm /> },
        { id: "createTenant", name: "Создание Организаций", component: <TenantForm /> },
        { id: "createProfile", name: "Создание Профиля устройств", component: <ProfileForm /> },
        { id: "createGateway", name: "Создание Шлюзов", component: <GatewayForm /> },
        { id: "createApplication", name: "Создание Групп устройств(Водоканалы, Товарищества)", component: <ApplicationForm /> },
        { id: "createAddress", name: "Создание Адресов", component: <AddressForm /> },
        { id: "createNbiotOrg", name: "Создание Организаций", component: <NbiotOrgForm /> },
        { id: "createNbiotGroup", name: "Создание Групп", component: <NbiotGroupForm /> }
    ];

    const selectedForm = forms.find(form => form.id === selectedMenu);

    return (
        <>
            {selectedMenu !== null && selectedForm && selectedForm.component}
        </>
    )
}

export default SettingDataContent;