import React, { useState, useRef, useEffect } from 'react';
import TreeDiv from '../AddressTree/AddressTree';
import ReportTable from '../ReportTable/ReportTable';
import s from "./Reports.module.css"



const Reports = ({ handleLogsClick }) => {
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [fullAddress, setFullAddress] = useState([]);
    const [searchAddressQuery, setSearchAddressQuery] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [openTree, setOpenTree] = useState(false)
    const popupRef = useRef(null);
    const buttonRef = useRef(null);

    const handleItemClick = (event) => {
        console.log('click');
        event.stopPropagation();
        setOpenTree(prevState => !prevState);
    };

    const handleClickOutside = (event) => {
        if (
            popupRef.current && !popupRef.current.contains(event.target) &&
            buttonRef.current && !buttonRef.current.contains(event.target)
        ) {
            setOpenTree(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const handleFullAddressChange = (address) => {
        setFullAddress(address)
    };

    return (
        <div className={s.main}>
            <div className={s.frame12}>
                <input
                    type='text'
                    className={s.search}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder='Поиск по imei, devEUI или номеру'
                >
                </input>
                <img src={'/search.svg'}></img>
            </div>


            <div className={s.frame62} onClick={handleItemClick} ref={buttonRef}>
                <div className={s.frame75} >
                    <div className={s.frame75logo}>
                        <img src={'/home.svg'}></img>
                    </div>
                    <div className={s.frame75Text}>Список адресов</div>
                    <div className={s.arrowDown}>
                        <img src={'/arrowback.svg'}></img>

                    </div>
                </div>
            </div>
            {openTree && (
                <div className={s.addressPopup} ref={popupRef}>
                    <div className={s.address}>
                        <div className={s.addressInput}>
                            <input
                                type='text'
                                className={s.addressSearch}
                                value={searchAddressQuery}
                                onChange={(e) => setSearchAddressQuery(e.target.value)}
                                placeholder='Поиск адреса'
                            >
                            </input>
                            <img src={'/search.svg'}></img>
                        </div>
                        <div>
                            <TreeDiv setSelectedAddress={(address) => {
                                setSelectedAddress(address);
                            }} handleFullAddressChange={handleFullAddressChange} />
                        </div>
                    </div>
                </div>
            )}
            <div>
                <ReportTable handleLogsClick={handleLogsClick} fullAddress={fullAddress} />
            </div>
        </div>

    )
}
export default Reports;
