
export async function sendDataToDeviceUpdate(deviceId, editableData, changes, originalData, username) {
  const authToken = localStorage.getItem('token');
  


  if (editableData) {
    const renameData = {
      "address": {
        "country": editableData["Адрес устройства"]["Страна"],
        "region": editableData["Адрес устройства"]["Область"],
        "city": editableData["Адрес устройства"]["Город"],
        "street": editableData["Адрес устройства"]["Улица"],
        "building": editableData["Адрес устройства"]["Дом"],
        "number": editableData["Адрес устройства"]["Квартира"],
        "latitude": editableData["Адрес устройства"]["Широта"],
        "longitude": editableData["Адрес устройства"]["Долгота"]
      },
      "paid_info": {
        "registry": editableData["Платежная информация"]["Реестр"],
        "ownership": editableData["Платежная информация"]["Владелец"],
        "type_ownership": editableData["Платежная информация"]["Форма собственности"],
        "devicecode": editableData["Платежная информация"]["Код прибора в ЕРИП"],
        "accountnumber": editableData["Платежная информация"]["Личевой счет"],
        "subscriptionarea": editableData["Платежная информация"]["Абонентский район"]
      },
      "device": {
        "dev_type": editableData["Данные устройства"]["Тип устройства"],
        "number": editableData["Данные устройства"]["Заводской номер"],
        "water_type": editableData["Данные устройства"]["Тип ресурса"],
        "brand": editableData["Данные устройства"]["Марка"],
        "diameter_body": editableData["Данные устройства"]["Диаметр (корпус)"],
        "commit": editableData["Данные устройства"]["Комментарий к устройству"],
        "verification_end_date": editableData["Данные устройства"]["Дата окончания поверки"],
        "factor": editableData["Данные устройства"]["Множитель"]
      },
      "device_iot": {
        "dev_type_iot": editableData["Устройство IoT"]["Тип устройства IoT"],
        "brand_iot": editableData["Устройство IoT"]["Марка IoT"],
        "token": editableData["Устройство IoT"]["Токен"],
        "manufacture": editableData["Устройство IoT"]["Производитель"]
      }
    };
    //         "number": editableData["Данные устройства"]["Заводской номер"],
    //         "deveui": editableData["Устройство IoT"]["DevEUI"],

    const requestData = {
      data: renameData,
      deviceid: deviceId,
      original: originalData,
      changes: changes,
      username: username,
      authToken: authToken,
    };
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/deviceupdate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ requestData }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Data updated successfully:', data);
      // Дополнительные действия после успешного обновления данных
    } catch (error) {
      console.error('There was an error updating the data:', error);
      throw error;
    }
  }
}


export async function fetchDeviceData(deviceId) {
  

  const [deviceUuid, deviceNumber] = deviceId.split('&');

  const url = new URL(`${process.env.REACT_APP_API_URL}/device/detail`);
  

  url.searchParams.append('_id', deviceUuid);
  url.searchParams.append('dev_number', deviceNumber);

  let response = await fetch(url);
  let data = await response.text();
  let obj = JSON.parse(data);

  return obj;

}


export async function getReports({ utilsData, user }) {
  

  let [selectedOption, toDate, fromDate] = utilsData;


  const formatDateToRFC3339 = (date) => date.toISOString();

  const getDateBoundary = (date, option) => {
    const newDate = new Date(date);
    if (option === 'startOfMonth') {
      return new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth() + 1, 0, 23, 59, 59, 999));
    } else if (option === 'startOfYear') {
      const lastMonthOfYear = 11;
      newDate.setMonth(lastMonthOfYear);
      newDate.setDate(31);
      return new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 23, 59, 59, 999));
    }
    return new Date(`${date}T23:59:59.999Z`);
  };

  const fetchToDate = formatDateToRFC3339(getDateBoundary(toDate, selectedOption));
  const fetchFromDate = formatDateToRFC3339(new Date(fromDate));


  const formData = new FormData();
  formData.append('detail', selectedOption);
  formData.append('toDate', fetchToDate);
  formData.append('fromDate', fetchFromDate);

  const url = new URL(`${process.env.REACT_APP_API_URL}/reports/get`);
  user.cs_group.forEach(csGroup => {
    url.searchParams.append('cs_group_id', csGroup._id);
  });
  user.nbiot_group.forEach(nbiotGroup => {
    url.searchParams.append('nbiot_group_id', nbiotGroup._id);
  });


  let response = await fetch(url, {
    method: 'POST',
    body: formData,
  });

  if (!response.ok) {
    throw new Error(`POST request failed: ${response.status}`);
  }

  let data = await response.json();
  console.log(data);
  return data;

}


export async function getDevDetailReports(id, dev_number, fromDate, toDate, selectedOption) {
  

  const formData = new FormData();
  formData.append('fromDate', fromDate);
  formData.append('toDate', toDate);
  formData.append('detail', selectedOption);

  const url = new URL(`${process.env.REACT_APP_API_URL}/reports/device`);
  url.searchParams.append('_id', id);
  url.searchParams.append('dev_number', dev_number);

  try {
    let response = await fetch(url, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`POST request failed: ${response.status}`);
    }

    let data = await response.json();

    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}


export async function getList(user) {
  

  const url = new URL(`${process.env.REACT_APP_API_URL}/device/device-list`);
  user.cs_group.forEach(csGroup => {
    url.searchParams.append('cs_group_id', csGroup._id);
  });
  user.nbiot_group.forEach(nbiotGroup => {
    url.searchParams.append('nbiot_group_id', nbiotGroup._id);
  });

  let response = await fetch(url);
  let data = await response.text();
  let obj = JSON.parse(data);
  let device = obj.map(function (item) {
    return item;
  });
  console.log(device);
  return device;
}


export async function getAddress(user) {
  

  const url = new URL(`${process.env.REACT_APP_API_URL}/address/get`);

  user.cs_group.forEach(csGroup => {
    url.searchParams.append('cs_group_id', csGroup._id);
  });
  user.nbiot_group.forEach(nbiotGroup => {
    url.searchParams.append('nbiot_group_id', nbiotGroup._id);
  });

  let response = await fetch(url);

  let data = await response.text();
  let obj = JSON.parse(data);

  return obj;
}


export async function filterDevice(i, user) {

  let device = await getList(user);
  if (i) {
    device = device.filter(item => {
      return item.address[0].region === i || item.address[0].city === i || item.address[0].street === i || item.address[0].building === i;
    });
  }
  return device;
}


export async function getDeviceById(deviceId, deviceNumber) {
  

  const url = new URL(`${process.env.REACT_APP_API_URL}/device/detail`);

  url.searchParams.append('_id', deviceId);
  url.searchParams.append('dev_number', deviceNumber);

  let response = await fetch(url);
  let data = await response.text();
  let obj = JSON.parse(data);
  return obj;
}

export async function importDevices(file) {
  

  const formData = new FormData();
  formData.append('file', file);

  const response = await fetch(`${process.env.REACT_APP_API_URL}/add-device`, {
    method: 'POST',
    body: formData
  });

  if (response.status === 201) {

    return { success: true };
  } else {
    return { success: false, error: 'Ошибка загрузки файла' };
  }
}


