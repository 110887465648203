import React, { useState, useEffect, useContext } from 'react';
import { TenantForm, CreateUserForm, UserForm, ProfileForm, GatewayForm, VerifyForm, AddressForm, ApplicationForm, updateUserContext, NbiotOrgForm, NbiotGroupForm } from './Forms';
import { Grid, List, Collapse, ListItem, ListItemButton, ListItemText } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

function SettingDataMenu({ onMenuChange }) {
    const [dropdowns, setDropdowns] = useState({});

    const toggleDropdown = (id) => {
        setDropdowns(prev => ({
            ...prev,
            [id]: !prev[id]
        }));
    };

    return (
        <Grid>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <ListItemButton onClick={() => toggleDropdown('lora')}>
                    <ListItemText primary="Настройки LoRa" />
                    {dropdowns.lora ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={dropdowns.lora} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }} key={'createTenant'} onClick={() => onMenuChange('createTenant')}>
                            <ListItemText primary="Организаций" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }} key={'createApplication'} onClick={() => onMenuChange('createApplication')}>
                            <ListItemText primary="Группы" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }} key={'createProfile'} onClick={() => onMenuChange('createProfile')}>
                            <ListItemText primary="Профиль устройства" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }} key={'createGateway'} onClick={() => onMenuChange('createGateway')}>
                            <ListItemText primary="Шлюзы" />
                        </ListItemButton>
                    </List>
                </Collapse>
                <ListItemButton onClick={() => toggleDropdown('nbiot')}>
                    <ListItemText primary="Найтройки NBIoT" />
                    {dropdowns.nbiot ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={dropdowns.nbiot} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }} key={'createNbiotOrg'} onClick={() => onMenuChange('createNbiotOrg')}>
                            <ListItemText primary="Организаций" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }} key={'createNbiotGroup'} onClick={() => onMenuChange('createNbiotGroup')}>
                            <ListItemText primary="Группы" />
                        </ListItemButton>
                    </List>
                </Collapse>
                <ListItemButton key={'users'} onClick={() => onMenuChange('users')}>
                    <ListItemText primary="Пользователи" />
                </ListItemButton>
            </List>
        </Grid>
    )
}

export default SettingDataMenu;